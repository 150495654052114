<template>
  <div class="personal-coaching">
    <b-container fluid class="section-header px-0">
      <div class="header-bg">
        <b-container class="hide-mobile">
          <b-row class="py-bw-5">
            <b-col></b-col>
            <b-col>
              <script
                defer
                type="application/javascript"
                src="https://api.lenus.io/v1/261ff416-450a-11eb-b6a2-93613d380362/form/lead-form"
              ></script>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container class="hide-desktop">
        <b-row class="py-bw-5">
          <b-col>
            <script
              defer
              type="application/javascript"
              src="https://api.lenus.io/v1/261ff416-450a-11eb-b6a2-93613d380362/form/lead-form"
            ></script>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .header-bg {
    @extend .img-background;
    height: calc(0.55 * 100vw);
    background-image: url('../assets/imgs/lenus/lenus_denis_bg.jpg');
    background-size: cover;
  }
</style>
